@import "styles/antd.scss";
@import "styles/amcharts.css";
@import "styles/scroll.css";

body {
  margin: 0;
  padding: 0;
  font-family: Verdana, Arial, sans-serif;
  font-size: 14px;
  cursor: default;
}

.text-upper {
  text-transform: uppercase;
}

.text-align-left {
  text-align: left;
}

.clicable {
  cursor: pointer;
}

/* Запрет на выделение текста */
.non-selectable {
  user-select: none;
}

/* Контейнер для иконки контента */
.content-item-container-icon {
  padding: 8px;
  border-radius: 5px !important;
  position: relative;
  z-index: 0;
}

.content-item-container-icon:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 10px;
}

/* Контейнер контента */
.content-item-container {
  padding: 16px;
  border-radius: 5px !important;
}

.dark-charts {
  background: #212121;
}

.light-charts {
  background: white
}

/* Контейнер контента. Наведение */
.content-item-container_hover {
  transition: box-shadow 0.2s ease;
}

.content-item-container_hover:hover {
  background: #0e0e0e;
  transition: 0.2s ease;
}

.full-screen-mode {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  border-radius: 0 !important;
}

.d-none {
  display: none !important;
}