.tag-add .ant-input-group-addon {
	padding: 0;
	border: 0;
}

.search-form-field {
	width: 100%;
}

.search-form-field__dropdown {
	width: 785px;
	max-width: 100vw;
}

.search-form-field__radio-group .ant-radio-button-wrapper {
	padding-inline: 10px;
}

.textareaContainer {
	height: calc(100% - 6px);
	border-radius: 8px
}

.textareaKeywordsSelectDark {
	border-color: #424242;
}

.textareaKeywordsSelectLight {
	border-color: #d9d9d9;
}

.textareaKeywordsSelectDark:focus, .textareaKeywordsSelectDark:hover {
	border: 1px solid #3c89e8;
}

.textareaKeywordsSelectLight:focus, .textareaKeywordsSelectLight:hover {
	border: 1px solid #1c5bad;
}

.textareaKeywordsSelectLight:focus {
	box-shadow: 0 0 0 2px rgba(3, 65, 107, 0.21);
}

.textareaKeywordsSelectDark:focus {
	box-shadow: 0 0 0 2px rgba(0, 60, 180, 0.15);
}

.textareaKeywordsSelectDark::placeholder {
	color: #505050;
	font-weight: 500;
}

.textareaKeywordsSelectLight::placeholder {
	color: #d9d9d9;
}

/*
red-9, orange-9, gold-9, yellow-9, lime-9, green-9, cyan-9, blue-9, purple-9, magenta-9
*/
.chemical {
	width: 30px;
	color: #fff;
}
.chemical--1 {
	background-color: #820014;
}
.chemical--2 {
	background-color: #873800;
}
.chemical--3 {
	background-color: #874d00;
}
.chemical--4 {
	background-color: #876800;
}
.chemical--5 {
	background-color: #3f6600;
}
.chemical--6 {
	background-color: #135200;
}
.chemical--7 {
	background-color: #00474f;
}
.chemical--8 {
	background-color: #003a8c;
}
.chemical--9 {
	background-color: #22075e;
}
.chemical--10 {
	background-color: #780650;
}
