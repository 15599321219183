.full-height-divider {
  height: 100%;
  margin: auto;
  left: 50%;
}

.competencies-chart {
  width: 100%;
  min-height: 350px;
  height: calc(100% - 40px);
}

.ant-btn-default:not(:disabled).favorite-button {
  &:hover,
  &:focus {
    border-color: #fadb14;
    color: #fadb14;
  }
}

.ant-btn-default:not(:disabled).expert-button {
  &:hover,
  &:focus {
    border-color: #fa8c16;
    color: #fa8c16;
  }
}

.ant-btn-default:not(:disabled).expert-button {
  &:hover {
    border-color: #7e2e2f;
    color: #7e2e2f;
  }
}
