.header {
  background: transparent;
  height: auto;
  margin-bottom: 32px;
  padding: 0;
};

.mainHeader {
  background: transparent;
  height: auto;
  padding: 0;
};

.content {
  max-width: 1800px;
  padding: 25px 32px 32px;
  width: 100%;
};

.mainmenu {
  width: 100%;
};

.mainLayout {
  max-width: 100%;
  min-height: 100vh
}

.contentWrapper {
  width: 100%
}
