.align-icon.ant-menu.ant-menu-inline-collapsed .ant-menu-item {
    padding: 4px calc(50% - 8px) 0 22px;
    margin-bottom: 8px;
}

.align-icon.ant-menu .ant-menu-item {
    margin-top: 25px;
}

.fixed-menu {
    position: fixed;
    left: 0;
    top: 0;
    width: 200px;
}

.fixed-menu--collapsed {
    width: 80px;
}
