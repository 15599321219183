::-webkit-scrollbar-track,
::-webkit-scrollbar-corner,
::-webkit-scrollbar {
	background-color: transparent;
}

::-webkit-scrollbar {
	width: 8px;
	max-height: 8px;
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:hover {
	background-color: #b7bbbf65;
}

::-webkit-scrollbar-button {
	width: 0;
	height: 0;
	display: none;
}
