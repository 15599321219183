.switch {
  padding: 4px;
  height: 25px;
  width: 40px;

  :global(.ant-switch-handle) {
    width: 20px;
    height: 20px;
  }

  &[aria-checked="true"] {
    :global(.ant-switch-handle) {
      transform: translateX(-2.5px);
    }
  }

  :global(.ant-switch-handle)::before {
    border-radius: 10px;
  }

  &[aria-checked="true"] {
    :global(.ant-switch-handle)::before {
      content: url(../../assets/icons/moon.svg);
    }
  }

  &[aria-checked="false"] {
    :global(.ant-switch-handle)::before {
      content: url(../../assets/icons/sun.svg);
    }
  }
}