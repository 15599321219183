button.analytic-link-btn {
	margin-top: 10px;
	margin-bottom: 10px;
}

button.analytic-link-btn:hover {
	color: #144848;
	border-color: #144848;
}

.ant-tag.tags-list-item {
	margin-bottom: 8px;
}

.space-scroll {
	max-height: 380px;
	overflow-y: scroll;
}

.search-panel-container {
	margin-bottom: 32px;
}

.ant-cascader-menu-item-content {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	word-break: break-all;
}

.ant-cascader-menus, .ant-cascader-menu { 
	height: 300px;
}

.find-patent-steps-container {
	margin-top: 24px;
	margin-bottom: 24px;
	padding: 12px;
	border-radius: 12px
}

.find-patent-steps-list {
	height: 300px;
	border-radius: 8px;
	overflow: scroll
}

.find-patent-steps-list-item {
	cursor: pointer;
}

.find-patent-steps-list-item-label {
	padding: 0 8px;
	margin: 0 8px;
	border-radius: 5px
}

.find-patent-steps-list-item-label:hover {
	background-color: rgba(133, 133, 133, 0.11);
}

.find-patent-steps-stepper {
	margin-top: 22px;
	margin-left: 22px;
	z-index: 100 !important;
}

.find-patent-steps-selected-container {
	border-radius: 8px;
	width: 130%;
	margin: 25px 25px 0 -30%;
	z-index: 0 !important;
}

.find-patent-steps-selected-item-container {
	position: relative;
	margin-bottom: 16px;
}

.find-patent-steps-selected-label {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	padding: 5px 8px;
	height: 32px;
	margin-left: 28%;
}

.find-patent-steps-selected-empty {
	height: 32px;
	margin-bottom: 16px;
}

.find-patent-steps-selected-background {
	position: absolute;
	width: 100%;
	height: 28px;
	top: 0;
	left: 0;
	border-radius: 0 5px 5px 0;
}

.find-patent-steps-select-title-container {
	margin-left: 20px;
	margin-top: -19px;
	width: calc(100% - 20px);
	text-overflow: ellipsis;
	overflow: hidden;
	display: inline-block;
	white-space: nowrap;
}

.find-patent-steps-select-title {
	font-size: 24px;
}
