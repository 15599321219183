.ant-layout {
  max-width: 1900px;
}

.ligth-theme.ant-layout,
.ligth-theme .ant-menu,
.ligth-theme .ant-table {
  background: transparent;
}

.ligth-theme .ant-layout-header {
  background: #f9f9fd;
}
