.help-content {
  .mark {
    color: #096dd9;
  }

  &__logo {
    position: relative;
    width: 100px;
    height: 100px;
    background: #002e6e;
    border-radius: 50px;
    overflow: hidden;

    img {
      width: 180px;
      position: absolute;
      left: 48%;
      top: 53%;
      transform: translate(-50%, -50%);
    }
  }
}
