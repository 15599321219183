.layout__content-modal-icon-expert {
  align-items: center;
  padding-bottom: 24px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.timeline-height {
  padding-top: 20px;
  max-height: 70vh;
  overflow: hidden auto;
}

.experts-page-more-tags-button {
  line-height: 20px;
  margin-top: 7px;
  padding: 0;
  height: fit-content;
}