/* Курсор типа "указатель" */
.cursor-pointer.ant-table-wrapper .ant-table-tbody > tr,
.clickable-table-row .ant-table-row {
	cursor: pointer;
}

// Dark theme
.ant-notification {
	color: hsla(0, 0%, 100%, 0.65);

	&-notice {
		$root: &;
		background: #1f1f1f;
		box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);

		#{$root}-message {
			color: hsla(0, 0%, 100%, 0.85);
		}

		#{$root}-close {
			color: hsla(0, 0%, 100%, 0.45);

			&:hover {
				color: rgba(255, 255, 255, 0.85);
			}
		}
	}
}
.ant-message-notice .ant-message-notice-content {
	color: hsla(0, 0%, 100%, 0.65);
	background: #1f1f1f;
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
}

.ant-layout {
	.ant-layout-sider {
		background: #1f1f1f;

		&-trigger {
			background: #262626;
		}
	}
}

.ant-menu-dark {
	background: #1f1f1f;
}

.ant-upload-wrapper .ant-upload-drag .ant-upload {
	padding: 16px;
}

.ant-btn {
	line-height: 1;
}

.ant-badge-blue {
	.ant-badge-count {
		background: #0041A0;
		color: #fff;
	}
}