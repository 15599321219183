$header-height: 46px;
$sider-width: 310px;
$dark-menu-item-background: #222222;
$light-menu-item-background: #FFFFFF;

.layout:global(.ant-layout),
.layout :global(.ant-layout) {
  max-width: none;
}

.header {
  display: flex;
  align-items: center;
  width: 100%;
  height: $header-height;
  backdrop-filter: blur(10px);
  z-index: 999;

  .row {
    width: 100%;
  }

  .buttons {
    padding-right: 60px;

    @media only screen and (max-width: 768px) {
      padding-right: 10px;
    }
  }
}

.logo {
  display: flex;
  align-items: center;
  justify-content: start;
  padding-left: 65px;
  margin: 0 10px;
  height: $header-height;
  font-weight: 700;
  font-size: 2rem;
  color: #1668DC;

  @media only screen and (max-width: 768px) {
    padding-left: 0px;
  }
}


.sider {
  margin-left: 60px;
  height: calc(100vh - $header-height - 20px);
  border-radius: 10px;

  :global {
    .ant-layout-sider-children {
      overflow: hidden;
      overflow-y: auto;
      padding: 1rem;
      margin-top: 5px;
    }

    .ant-layout-sider-zero-width-trigger {
      top: 0px;
      left: -10px;
      transform: translateX(-100%);
      border-radius: 100%;
      background-color: transparent;

      &:after {
        background: transparent;
        border-radius: 100%;
      }

      &:hover {
        &:after {
        background: rgba(183, 187, 191, 0.17);
        }
      }

    }
  }

  &:global(.ant-layout-sider-collapsed) {
    :global {
      .ant-layout-sider-children {
        padding: 0;
      }
    }
  }
}

.content {
  padding: calc($header-height + 5px) 1rem 1.5rem 1rem;
  min-width: 500px;
  overflow-y: auto;
  height: 100vh;
  margin-top: -$header-height;

  :global(.ant-layout-content) {
    min-height: auto;
    margin-bottom: 1rem;
  }
}

.layoutHeader {
  background: transparent;
  height: auto;
  margin-bottom: 32px;
  padding: 0;
};

.layoutMainHeader {
  background: transparent;
  height: auto;
  padding: 0;
};

.topMenu {
  background-color: transparent;
  border: none;

  :global {
    .ant-menu-overflow-item {
      height: calc($header-height - 16px);
      top: 0 !important;
      transition: none;
    }

    .ant-menu-submenu, .ant-menu-item {
      height: calc($header-height - 16px);
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 2px;

      &:after {
        border: none !important;
      }
    }

    .ant-menu-item-selected, .ant-menu-submenu-selected {
      border-radius: 8px !important;
    }

    .ant-menu-submenu, .ant-menu-item {
      &:hover {
        border-radius: 8px;
      }
    }
  }

}

.lightTextTopMenu {
  :global {
    .ant-menu-item-selected, .ant-menu-submenu-selected {
      background-color: $dark-menu-item-background !important;
    }

    .ant-menu-submenu, .ant-menu-item {
      &:hover {
        background-color: $dark-menu-item-background;
      }
    }

    .ant-menu-title-content,
    .ant-menu-icon,
    .ant-menu-item-icon,
    .ant-menu-submenu-title {
      color: white !important;
    }
  }
}

.darkTextTopMenu {
  :global {
    .ant-menu-item-selected, .ant-menu-submenu-selected {
      background-color: $light-menu-item-background !important;
    }

    .ant-menu-submenu, .ant-menu-item {
      &:hover {
        background-color: $light-menu-item-background;
      }
    }

    .ant-menu-title-content,
    .ant-menu-icon,
    .ant-menu-item-icon,
    .ant-menu-submenu-title {
      color: black !important;
    }
  }
}
